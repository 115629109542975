<template>
  <validation-provider
    :name="label || placeholder"
    :rules="rules"
    v-slot="validationContext"
    :vid="vid"
  >
    <b-form-group :description="description" :label="(label||'')+(isRequired&&label.length>0 ? ' *':'')" :label-for="id">
      <component
        :is="componentName"
        :id="id"
        :name="id"
        :value="value"
        @input="handleInput"
        v-bind="$attrs"
        v-on="$listeners"
        :aria-describedby="`${id}-feedback`"
        :state="getValidationState(validationContext)"
      ></component>
      <b-form-invalid-feedback :id="`${id}-feedback`">
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
import id from "vee-validate/dist/locale/id.json";
import { localize, extend, ValidationProvider } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import { v4 as uuidv4 } from "uuid";
import { BFormGroup, BFormInvalidFeedback, BFormInput, BFormDatepicker,BFormTextarea } from "bootstrap-vue";
import isUndefined from 'lodash/isUndefined'

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});
localize("id", id);

export default {
  name: "FormComponent",
  props: {
    componentName: String,
    label: String,
    description: String,
    placeholder: String,
    vid: String,
    value: [String, Number, Array, Object],
    rules: Object,
  },
  components: {
    BFormGroup,
    BFormInvalidFeedback,
    ValidationProvider,
    BFormInput,
    BFormDatepicker,
    BFormTextarea
  },
  data() {
    return {
      id: "",
    };
  },
  computed:{
    isRequired(){
      const rule = this.rules
      if(isUndefined(rule)||isUndefined(rule.required)) return false
      else return this.rules.required
    }
  },
  methods: {
    handleInput(e) {
      this.$emit("input", e);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? (valid ? null : false) : null;
    },
  },
  created() {
    this.id = uuidv4();
  },
};
</script>

<style>
</style>